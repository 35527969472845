import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';

const COLORS = {
  cerise: 'var(--g-cerise)',
  purple: 'var(--c-purple)',
  grey: 'var(--c-border-light)',
};

const SDiv = styled.div`
  width: 100%;
  max-width: ${({ width }) => (width ? width + 'px' : '500px')};
  box-sizing: border-box;
`;

const Icon = styled.div`
  width: calc(var(--icon-width));
  height: calc(var(--icon-height));
  background: ${({ color }) => COLORS[color]};
  border-radius: var(--br-box);
  color: ${({ textColor }) => (textColor === 'white' ? 'var(--c-always-light)' : textColor)};
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  font-family: var(--ff-wide);
  font-weight: var(--fw-h3);
  font-size: var(--fs-h3);
  margin-bottom: 0.5rem;

  img {
    filter: var(--svgFilter);
  }
`;

const Text = styled.p`
  font-family: var(--ff-headline);
  font-weight: 400;
  font-size: var(--fs-h3);
  line-height: 1.25;
  margin-right: calc(2 * var(--side-margin));
  text-align: left;

  .block {
    font-size: var(--fs-p);
  }
`;

const Subtitle = styled.div`
  color: var(--c-fg-1);
`;

interface SmalBlockProps {
  block: boolean;
  color?: 'cerise' | 'purple' | 'grey';
  icon?: string;
  step?: number | string;
  stepColor?: string;
  subtitle?: string;
  text?: string;
  title?: string;
  w?: number;
}

export function SmolBlock({
  block,
  color = 'grey',
  icon,
  step,
  stepColor,
  subtitle,
  text,
  title,
  w,
  ...props
}: SmalBlockProps) {
  const iconTextColor = color === 'cerise' || color === 'purple' ? 'white' : stepColor;

  return (
    <SDiv width={w} {...props}>
      <Icon color={color} icon={icon} textColor={iconTextColor}>
        {icon && <img src={icon} alt="" height="40" loading="lazy" />}
        {step}
      </Icon>
      {!!text && (
        <Text>
          {!!title && (
            <span className="m">
              {title}
              {!!subtitle && (
                <>
                  <br />
                  <Subtitle>{subtitle}</Subtitle>
                </>
              )}{' '}
            </span>
          )}
          <span className={clsx('fg2', 'l', block && 'block')}>{text}</span>
        </Text>
      )}
    </SDiv>
  );
}

export default SmolBlock;
